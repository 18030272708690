<template>
    <div>
        <global-page-back
            detailPageTitle="库房详情"
            @handlerGlobalType="clickBackBtn"
        >
        </global-page-back>
        <div class="content-main">
            <div class="filter-wrap">
                 <expand-filter
                    :formData="formData"
                    @clickBtn="clickBtn"
                    marginBottom="0px"
                    :closeWidth="680"
                    @changeBtnFormType="changeBtnFormType"
                />
                <div class="button-line"></div>
                <div>
                    <el-button
                        type="enquiry"
                        @click="handleImport()"
                    >
                        初次入库
                    </el-button>
                    <el-button
                        type="enquiry"
                        @click="handleExport"
                    >
                    导出
                    </el-button>
                </div>
            </div>

            <div class="operation-btn-view">
                <div>
                    <span class="view-label">当前数量：</span>
                    <span class="view-value">{{ currentNum }}</span>
                    <span class="view-label">总金额： </span>
                    <span class="view-value"
                        >{{
                            totalMoney ? totalMoney.toLocaleString() : ""
                        }}
                        元</span
                    >
                </div>
            </div>
            <table-data
                v-loading="loadingTable"
                ref="table"
                :config="table_config"
                :tableData="table_data"
            >
                <template v-slot:operation="{data}">
                    <xk-button
                        type="primary"
                        size="mini"
                        @click="handleIn(data)"
                        >入库</xk-button
                    >
                    <xk-button
                        type="primary"
                        size="mini"
                        @click="handleOut(data)"
                        >出库</xk-button
                    >
                    <xk-button
                        type="warning"
                        size="mini"
                        @click="handleRepair(data)"
                        >报修</xk-button
                    >
                    <xk-button
                        type="success"
                        size="mini"
                        @click="handleDetail(data)"
                        >详情</xk-button
                    >
                    <xk-button
                        type="danger"
                        size="mini"
                        @click="handleDel(data)"
                        >删除</xk-button
                    >
                </template>
            </table-data>
            <Pagination
                :total="total"
                :page.sync="listQuery.pageNum"
                :limit.sync="listQuery.pageRow"
                @pagination="getList()"
            />
            <!-- 添加资产名 -->
            <dialog-wrapper
                :dialogObj="addAssetsDialogObj"
                @handleClose="addAssetsClose"
            >
                <add-assets
                    ref="addAsset"
                    :rule-form="addAssetRulesForm"
                    :rules="addAssetRules"
                    :list="categoryList"
                    @submit="addAssetsSubmit"
                    @close="addAssetsClose"
                />
            </dialog-wrapper>
            <!-- 导入 -->
            <!-- <dialog-wrapper :dialogObj="importDialogObj" @handleClose="importClose">
                <import-assets
                    :data="storeData"
                    @downloadTemplate="downloadTemplate"
                    @downloadErrorList="downloadErrorList"
                    @importSuccess="importSuccess"
                    @submit="importClose"
                    @close="importClose"
                />
            </dialog-wrapper> -->
            <!-- 初次入库 -->
            <dialog-wrapper
                :dialogObj="importDialogObj"
                @handleClose="importClose"
            >
                <InitialImport
                    v-if="importDialogObj.dialogVisible"
                    ref="importFamilyRef"
                    :import-form="importFamilyRuleForm"
                    @download="downloadTemplate"
                    @save="handleImportFamilySave"
                    @close="importClose"
                />
            </dialog-wrapper>
            <!--入库-->
            <dialog-wrapper
                :dialogObj="inDialogObj"
                @handleClose="inClose"
                class="form-dialog"
            >
                <in-storage
                    ref="inRef"
                    :show-data="inShowData"
                    :rule-form="inRulesForm"
                    :rules="inRules"
                    @submit="inSubmit"
                    @close="inClose"
                />
            </dialog-wrapper>
            <!--出库-->
            <dialog-wrapper
                :dialogObj="outDialogObj"
                @handleClose="outClose"
                class="form-dialog"
            >
                <out-storage
                    :key="outDialogObj.key"
                    ref="outRef"
                    :show-data="outShowData"
                    :rule-form="outRulesForm"
                    :rules="outRules"
                    :organList="organList"
                    :userList="userList"
                    @submit="outSubmit"
                    @close="outClose"
                    @handleDeptChange="handleDeptChange"
                    @handleUserChange="handleUserChange"
                    @handleUserClear="handleUserClear"
                    @handleDeptClear="handleDeptClear"
                />
            </dialog-wrapper>
            <!--报修-->
            <dialog-wrapper
                :dialogObj="repairDialogObj"
                @handleClose="repairClose"
                class="form-dialog"
            >
                <report-repair
                    :key="repairDialogObj.key"
                    ref="repairRef"
                    :rule-form="repairRulesForm"
                    :rules="repairRules"
                    :userList="allUserList"
                    @submit="repairSubmit"
                    @close="repairClose"
                />
            </dialog-wrapper>
            <!--详情-->
            <dialog-wrapper
                :dialogObj="detailDialogObj"
                @handleClose="detailClose"
                class="form-dialog"
            >
                <detail-assets
                    :data="detailData"
                    @submit="detailClose"
                    @close="detailClose"
                />
            </dialog-wrapper>
        </div>
    </div>
</template>

<script>
import {
    // 业务组件
    Pagination,
    DialogWrapper,
    // 工具函数
} from "common-local";
import TableData from "../Sub/TableData"
import ExpandFilter from "../Sub/ExpandFilter";
// 添加资产名
import AddAssets from "./Warehouse/AddAssets";
// 导入
// import ImportAssets from "./Warehouse/ImportAssets";
// 入库
import InStorage from "./Warehouse/InStorage";
// 出库
import OutStorage from "./Warehouse/OutStorage";
// 报修
import ReportRepair from "./Warehouse/ReportRepair";
// 详情
import DetailAssets from "./Warehouse/DetailAssets";
//初次入库
import InitialImport from "./InitialImport/index";
import {AssetWarehouseAssetsModel} from "@/models/AssetWarehouseAssets.js";

import {downloadFile, formatTreeData} from "@/libs/utils";
import onResize from "@/mixins/onResize"

import store from "@/store";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
const schoolId = store.state.schoolId;
const productId = "cloud_campus";

export default {
    mixins: [onResize],
    props: {
        storeData: {
            type: Object,
        },
    },
    components: {
        GlobalPageBack,
        AddAssets,
        // ImportAssets,
        InStorage,
        OutStorage,
        ReportRepair,
        DetailAssets,
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        InitialImport,
    },
    data() {
        return {
            formData: {
                data: [
                    {
                        type: "select",
                        label: "",
                        placeholder: "请选择资产品类",
                        key: "categoryId",
                        value: "",
                        list: [],
                    },
                    {
                        type: "input",
                        label: "",
                        placeholder: "请输入资产名称",
                        key: "assetsName",
                        value: "",
                    },
                    {
                        type: "input",
                        label: "",
                        placeholder: "请输入资产编号",
                        key: "assetsCode",
                        value: "",
                    },
                    {
                        type: "select",
                        label: "",
                        placeholder: "请选择资产类型",
                        key: "assetsType",
                        value: "",
                        list: [
                            {
                                label: "全部",
                                value: "",
                            },
                            {
                                label: "固定资产",
                                value: "1",
                            },
                            {
                                label: "耗材资产",
                                value: "2",
                            },
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                ],
                btnFormType: true,
            },
            table_config: {
                thead: [
                    {
                        label: "资产品类",
                        prop: "categoryName",
                        type: "popoverText",
                        align: 'center'
                    },
                    {
                        type: "function",
                        label: "资产类型",
                        align: 'center',
                        callBack(row) {
                            return {
                                1: "固定资产",
                                2: "耗材资产",
                            }[+row.assetsType];
                        },
                    },
                    {
                        label: "资产名称",
                        prop: "assetsName",
                        type: "popoverText",
                        align: 'center'
                    },
                    {
                        label: "资产编号",
                        prop: "assetsCode",
                        align: "center",
                        labelWidth: "200",
                    },
                    {
                        label: "资产数量",
                        prop: "assetsCount",
                        align: "center",
                        labelWidth: "200",
                    },
                    {
                        type: "function",
                        label: "资产总额",
                        labelDescription: "单位：元",
                        prop: "assetsTotal",
                        align: 'center',
                        callBack(row) {
                            if (row.assetsTotal) {
                                return row.assetsTotal.toLocaleString();
                            } else {
                                return 0;
                            }
                        },
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                height: "",
                check: false,
            },
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: schoolId,
                productId: productId,
                storeId: "",
                categoryId: "",
                assetsName: "",
                assetsCode: "",
                assetsType: "",
            },
            // 表格数据
            table_data: [],
            // 分页器
            total: 0,
            // 功能组件
            loadingTable: true,
            // 添加
            addAssetsDialogObj: {
                title: "添加资产",
                dialogVisible: false,
                width: "auto",
            },
            addAssetRulesForm: {
                schoolId: schoolId,
                storeId: "",
                productId: productId,
                assetsName: "",
                categoryId: "",
                categoryName: "",
                assetsType: "",
                assetsBrand: "",
                assetsModel: "",
                assetsSerialNumber: "",
                assetsUnit: "",
            },
            addAssetRules: {
                assetsName: [
                    {
                        required: true,
                        message: "请输入资产名称",
                        trigger: "blur",
                    },
                ],
                categoryId: [
                    {
                        required: true,
                        message: "请选择资产品类",
                        trigger: "change",
                    },
                ],
                assetsType: [
                    {
                        required: true,
                        message: "请选择资产类型",
                        trigger: "change",
                    },
                ],
                assetsUnit: [
                    {
                        required: true,
                        message: "请输入计量单位",
                        trigger: "blur",
                    },
                ],
            },
            // 导入
            importDialogObj: {
                title: "初次入库",
                dialogVisible: false,
                width: "900px",
            },
            importFamilyRuleForm: {
                schoolId: "",
                active: 0,
                file: "",
            },
            // 入库
            inDialogObj: {
                title: "资产入库",
                dialogVisible: false,
                width: "auto",
            },
            inShowData: {
                assetsUnit: "",
                assetsCode: "",
                assetsName: "",
            },
            inRulesForm: {
                assetsId: "",
                assetsSupplier: "",
                buyTime: "",
                unitPrice: "",
                inNumber: "",
            },
            inRules: {
                buyTime: [
                    {
                        required: true,
                        message: "请选择采购日期",
                        trigger: "change",
                    },
                ],
                unitPrice: [
                    {
                        required: true,
                        message: "请输入采购单价",
                        trigger: "change",
                    },
                    {
                        pattern: /^\d+(\.{0,1}\d+){0,1}$/g,
                        message: "采购单价格式不正确",
                    },
                ],
                inNumber: [
                    {
                        required: true,
                        message: "请输入入库数量",
                        trigger: "change",
                    },
                    {
                        pattern: /^\+?[1-9]\d*$/g,
                        message: "入库数量只允许输入正整数",
                    },
                ],
            },
            // 出库
            outDialogObj: {
                key: 0,
                title: "资产出库",
                dialogVisible: false,
                width: "auto",
            },
            outShowData: {
                assetsUnit: "",
                assetsCode: "",
                assetsName: "",
                assetsCount: "",
            },
            outRulesForm: {
                assetsId: "",
                collectType: "",
                costFrom: "",
                collectDept: "",
                collectDeptName: "",
                collectPerson: "",
                collectPersonName: "",
                operateTime: "",
                operateNumber: "",
                operateReason: "",
            },
            outRules: {
                collectType: [
                    {
                        required: true,
                        message: "请选择领方类型",
                        trigger: "change",
                    },
                ],
                costFrom: [
                    {
                        required: true,
                        message: "请选择费用归属",
                        trigger: "change",
                    },
                ],
                collectDept: [
                    {
                        required: true,
                        message: "请选择领用部门",
                        trigger: "change",
                    },
                ],
                operateTime: [
                    {
                        required: true,
                        message: "请选择出库日期",
                        trigger: "change",
                    },
                ],
                operateNumber: [
                    {
                        required: true,
                        message: "请输入分配数量",
                        trigger: "change",
                    },
                ],
            },
            // 报修
            repairDialogObj: {
                key: 1,
                title: "资产报修",
                dialogVisible: false,
                width: "auto",
            },
            repairRulesForm: {
                schoolId: schoolId,
                storeId: "",
                assetsId: "",
                assetsCode: "",
                assetsName: "",
                assetsType: "",
                assetsCount: "",
                assetsBrand: "",
                assetsModel: "",
                assetsSerialNumber: "",
                categoryId: "",
                categoryName: "",
                fixContent: "",
                operateNumber: "",
                fixAmount: "",
                fixPerson: "",
                declarer: "",
                emergencyLevel: "1",
                assetsUnit: "",
                remark: "",
            },
            repairRules: {
                fixContent: [
                    {
                        required: true,
                        message: "请输入维修内容",
                        trigger: "change",
                    },
                ],
                operateNumber: [
                    {
                        required: true,
                        message: "请输入维修数量",
                        trigger: "blur",
                    },
                    {
                        pattern: /^\+?[1-9]\d*$/g,
                        message: "维修数量只允许输入正整数",
                    },
                ],
                fixAmount: [
                    {
                        required: true,
                        message: "请输入维修金额",
                        trigger: "blur",
                    },
                    {
                        pattern: /^\d+(\.{0,1}\d+){0,1}$/g,
                        message: "维修金额格式不正确",
                    },
                ],
                fixPerson: [
                    {
                        required: true,
                        message: "请输入维修人",
                        trigger: "change",
                    },
                ],
            },
            // 详情
            detailDialogObj: {
                title: "资产详情",
                dialogVisible: false,
                width: "800px",
            },
            detailData: {},
            // 删除
            // 当前数量
            currentNum: 0,
            // 总金额
            totalMoney: 30000,
            // 资产品类
            categoryList: [],
            // 组织机构
            organList: [],
            // 根据部门查询的人员下拉
            userList: [],
            // 全部用户
            allUserList: [],
        };
    },
    created() {
        this.init();
    },
    computed: {
    },
    mounted() {

    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.$refs.table) {
            this.$refs.table.$refs.multipleTable.doLayout();
        }
    },
    methods: {
        init() {
            this.listQuery.storeId = this.storeData.id;
            this.getList();
            this.getSchoolStoreCategory();
            this.getOrgList();
            this.getAllUserList();
        },
        // table 分页器
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            this._fet("/school/schoolStoreAssets/list", this.listQuery).then(
                (res) => {
                    this.handleRes(res, () => {
                        this.total = res.data.data.totalCount;
                        this.table_data = res.data.data.list;
                        this.currentNum = res.data.data.count;
                        this.totalMoney = res.data.data.total;
                    });
                    this.loadingTable = false;
                },
            );
        },
        // 获取资产品类
        getSchoolStoreCategory() {
            this._fet("/school/schoolStoreCategory/listByCondition", {
                schoolId: this.listQuery.schoolId,
                storeId: this.listQuery.storeId,
            }).then((res) => {
                this.handleRes(res, () => {
                    this.categoryList = res.data.data.map((i) => ({
                        label: i.categoryName,
                        value: i.id,
                    }));
                    this.formData.data.forEach((item) => {
                        if (item.key === "categoryId") {
                            item.list = this.categoryList;
                        }
                    });
                });
            });
        },
        // 获取组织机构
        getOrgList() {
            this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.$store.state.schoolId,
                organType: "1",
            }).then((res) => {
                this.organList = formatTreeData(
                    res.data.data.list,
                    "id",
                    "parentOrg",
                );
                // this.organList = res.data.data
            });
        },
        // 获取全部教职工信息
        getAllUserList() {
            this._fet("/school/schoolOrgan/getIssueScopeByAccess", {
                schoolId: this.listQuery.schoolId,
                applyPerson: "1",
            }).then((res) => {
                this.handleRes(res, () => {
                    this.allUserList = res.data.data;
                });
            });
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary":
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset":
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.listQuery.createBy = "";
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        // 返回资产管理列表页
        clickBackBtn(){
            this.$emit("changeComp", {
                index: 0,
                data: {},
            });
        },
        /*
            添加资产
         */
        addAssets() {
            this.addAssetsDialogObj.dialogVisible = true;
        },
        addAssetsClose() {
            this.addAssetRulesForm = this.$options.data().addAssetRulesForm;
            this.$refs.addAsset.$refs.formRef.clearValidate();
            this.addAssetsDialogObj.dialogVisible = false;
        },
        addAssetsSubmit() {
            let form = JSON.parse(JSON.stringify(this.addAssetRulesForm));
            form.storeId = this.storeData.id;
            form.categoryName = this.categoryList.find(
                (i) => i.value === form.categoryId,
            ).label;
            this._fet("/school/schoolStoreAssets/save", form).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success(
                        this.addAssetsDialogObj.title + "成功",
                    );
                    this.getList();
                    this.addAssetsClose();
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /*
            入库
         */
        handleIn(data) {
            Object.keys(this.inShowData).forEach((key) => {
                this.inShowData[key] = data[key];
            });
            this.inRulesForm.assetsId = data.id;
            this.inDialogObj.dialogVisible = true;
        },
        inClose() {
            this.inRulesForm = this.$options.data().inRulesForm;
            this.inShowData = this.$options.data().inShowData;
            this.$refs.inRef.$refs.formRef.clearValidate();
            this.inDialogObj.dialogVisible = false;
        },
        inSubmit() {
            let form = JSON.parse(JSON.stringify(this.inRulesForm));
            form.inNumber = Number(form.inNumber);
            this._fet("/school/schoolStoreAssetsIn/assetStorage", form).then(
                (res) => {
                    if (res.data.code === "200") {
                        this.$message.success(this.inDialogObj.title + "成功");
                        this.getList();
                        this.inClose();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                },
            );
        },
        /*
            出库
         */
        handleOut(data) {
            Object.keys(this.outShowData).forEach((key) => {
                this.outShowData[key] = data[key];
            });
            this.outRulesForm.assetsId = data.id;
            this.outDialogObj.dialogVisible = true;
            this.outDialogObj.key++;
        },
        handleDeptChange(data) {
            this.outRulesForm.collectDeptName = data.name;
            this.getUserList(data);
        },
        handleUserChange(data) {
            this.outRulesForm.collectPersonName = data.name;
        },
        handleDeptClear() {
            this.outRulesForm.collectDeptName = "";
            this.outRulesForm.collectPersonName = "";
            this.outRulesForm.collectPerson = "";
            this.userList = [];
        },
        handleUserClear() {
            this.outRulesForm.collectPersonName = "";
        },
        // 获取人员
        getUserList(node) {
            this._fet("/school/schoolOrgan/getPeopleList", {
                schoolId: this.listQuery.schoolId,
                organType: "1",
                id: node.id,
            }).then((res) => {
                this.handleRes(res, () => {
                    this.userList = res.data.data;
                });
            });
        },
        outClose() {
            this.outRulesForm = this.$options.data().outRulesForm;
            this.outShowData = this.$options.data().outShowData;
            this.$refs.outRef.$refs.formRef.clearValidate();
            this.userList = [];
            this.outDialogObj.dialogVisible = false;
        },
        outSubmit() {
            let form = JSON.parse(JSON.stringify(this.outRulesForm));
            this._fet("/school/schoolStoreAssetsIn/assetDelivery", form).then(
                (res) => {
                    if (res.data.code === "200") {
                        this.$message.success(this.outDialogObj.title + "成功");
                        this.getList();
                        this.outClose();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                },
            );
        },
        /*
            报修
         */
        handleRepair(data) {
            const keyList = [
                "assetsType",
                "assetsCode",
                "assetsName",
                "assetsUnit",
                "assetsCount",
                "assetsBrand",
                "assetsSerialNumber",
                "assetsModel",
                "categoryName",
                "categoryId",
            ];
            keyList.forEach((key) => {
                this.repairRulesForm[key] = data[key];
            });
            this.repairRulesForm.storeId = this.storeData.id;
            this.repairRulesForm.assetsId = data.id;
            this.repairDialogObj.dialogVisible = true;
            this.repairDialogObj.key++;
        },
        repairClose() {
            this.repairRulesForm = this.$options.data().repairRulesForm;
            this.$refs.repairRef.$refs.formRef.clearValidate();
            this.repairDialogObj.dialogVisible = false;
        },
        repairSubmit() {
            let form = JSON.parse(JSON.stringify(this.repairRulesForm));
            this._fet(
                "/school/schoolStoreAssetsRecord/reportForRepair",
                form,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success(this.repairDialogObj.title + "成功");
                    this.getList();
                    this.repairClose();
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /*
            详情
         */
        handleDetail(data) {
            this._fet("/school/schoolStoreAssets/info", {
                id: data.id,
            }).then((res) => {
                this.handleRes(res, () => {
                    this.detailData = res.data.data;
                    this.detailDialogObj.dialogVisible = true;
                });
            });
        },
        detailClose() {
            this.detailData = this.$options.data().detailData;
            this.detailDialogObj.dialogVisible = false;
        },
        /*
            删除
         */
        handleDel(data) {
            this.$confirm("请谨慎删除，资产删除将同时删除资产、资产全部数据及资产出入库流水，且无法恢复！", "删除确认", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const param = {
                        id: data.id,
                        schoolId: schoolId,
                        storeId: this.storeData.id,
                        productId: productId,
                    };
                    this._fet("/school/schoolStoreAssets/delete", param).then(
                        (res) => {
                            this.handleRes(res, () => {
                                this.$message.success("删除成功!");
                                this.getList();
                            });
                        },
                    );
                })
                .catch(() => {});
        },
        /**
         * @Description: 打开初次入库弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-02-22 10:20:59
         */
        handleImport() {
            this.importDialogObj.dialogVisible = true;
        },
        /**
         * @Description: 初次入库----下一步
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-02-22 10:20:31
         */
        handleImportFamilySave() {
            if (this.importFamilyRuleForm.file === "") {
                this.$message.warning("请上传文件");
            } else {
                const file = this.importFamilyRuleForm.file;
                let formData = new FormData();
                formData.append("file", file.raw);
                formData.append("schoolId", schoolId);
                formData.append("productId", "cloud_campus");
                formData.append("storeId", this.storeData.id);
                console.log(file);
                const assetWarehouseAssetsModel =
                    new AssetWarehouseAssetsModel();
                assetWarehouseAssetsModel
                    .InitialImport(formData)
                    .then((res) => {
                        if (res.data.code === "200") {
                            this.importFamilyRuleForm.active++;
                            this.$message.success("导入成功");
                            this.getList();
                        } else if (res.data.code === "400") {
                            this.$message.error(res.data.msg);
                        } else {
                            this.getList();
                            this.downloadErrorList(res.data);
                        }
                    });
            }
        },
        /**
         * @Description: 初次入库---下载模板
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-02-22 10:21:35
         */
        async downloadTemplate() {
            const {data} = await this._get(
                "/school/schoolStoreAssets/downloadTemplate",
            );
            downloadFile(
                {
                    url: data.data,
                    method: "get",
                },
                () => {},
                () => {},
            );
        },
        /**
         * @Description: 关闭初次入库弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-02-22 10:22:17
         */
        importClose() {
            this.importDialogObj.dialogVisible = false;
            this.importFamilyRuleForm.active = 0;
            this.importFamilyRuleForm.file = "";
        },
        /**
         * @Description: 错误信息导出接口
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-02-21 18:54:58
         * @param {*} res
         */
        downloadErrorList(res) {
            this.$confirm(res.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: "/school/schoolStoreAssets/assetsErrorExport",
                            method: "post",
                            form: {
                                paramData: res.info.paramData,
                            },
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        /*
            导出
         */
        handleExport() {
            const obj = {
                schoolId: schoolId,
                storeId: this.storeData.id,
            };
            this.formData.data
                .filter((i) => i.value)
                .forEach((item) => {
                    obj[item.key] = item.value;
                });
            downloadFile(
                {
                    url: "/school/schoolStoreAssets/export",
                    method: "post",
                    form: obj,
                },
                () => {},
                () => {},
            );
        },

        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;

    .expand-filter {
        padding: 0;
    }
}
.operation-btn-view {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    background-color: #fff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .view-label {
        font-size: 14px;
        color: #737373;
        font-weight: bold;
    }

    .view-value {
        margin-right: 30px;
        font-weight: bold;
        color: #7a8a99;
    }
}
.form-dialog {
    ::v-deep .el-dialog__body {
        padding-top: 0;
    }
}
</style>
