<template>
    <!-- 教务综合/资产管理/仓库资产 -->
    <div class="asset-departmental-assets-layout">
        <component
            v-bind:is="currentComponent"
            :storeData="storeData"
            @changeComp="changeComp"
            @setPageInitTabs="setPageInitTabs"
        ></component>
    </div>
</template>

<script>
import List from './AssetWarehouseAssets/index.vue';
import Warehouse from "./AssetWarehouseAssets/Warehouse.vue";
import WarehouseFlow from './AssetWarehouseAssets/WarehouseFlow.vue';

export default {
    name: "AssetDepartmentalAssetsScrollWrapper",
    components: {
        List,
        Warehouse,
        WarehouseFlow
    },
    data () {
        return {
            comList: ['List', 'Warehouse', 'WarehouseFlow'],
            idx: 0,
            storeData: {},
            type: 'list',
        }
    },
    computed: {
        currentComponent () {
            return this.comList[this.idx];
        }
    },
    activated() {
        this.setGlobalPageType(this.type)
    },
    methods: {
        changeComp({index, data}) {
            this.idx = index
            Object.assign(this.storeData, data)
            let type = index === 0 ? 'list' : 'detail'
            this.setGlobalPageType(type)
        },
        setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
        },
        setPageInitTabs(){
            this.setGlobalPageType('list')
            this.idx = 0;
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
