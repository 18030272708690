<template>
    <div class="content-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
            >
                <expand-filter
                    :closeWidth="170"
                    :formData="formData"
                    @changeBtnFormType="changeBtnFormType"
                    @clickBtn="clickBtn"
                ></expand-filter>
                <div class="title">
                    资产总金额：{{
                        totalMoney ? totalMoney.toLocaleString() : 0
                    }}元
                </div>
                <table-data
                    ref="table"
                    v-loading="loadingTable"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor"
                >
                    <template v-slot:operation="slotData">
                        <el-button
                            @click="goWarehouse(slotData.data)"
                            v-hasPermi="['assetWarehouseAssets:enter']"
                            type="warning"
                            size="mini"
                            >进入库房</el-button
                        >
                        <el-button
                            @click="goFlow(slotData.data)"
                            v-hasPermi="['assetWarehouseAssets:waterRecord']"
                            type="danger"
                            size="mini"
                            >查看流水</el-button
                        >
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
} from "common-local";
import TableData from "../Sub/TableData"
// Ajax 请求
import {SystemModel} from "@/models/System.js";
import ExpandFilter from "../Sub/ExpandFilter";
import onResize from "@/mixins/onResize";
import RecordsList from '@/libs/pageListOptimal.js';
export default {
    mixins: [onResize],
    // name: "List",
    components: {
        TableData,
        DialogWrapper,
        Pagination,
        // 功能组件
        Error,
        Loading,
        ExpandFilter
    },
    data() {
        return {
            loadingTable: true,
            totalMoney: "", //资产总金额
            listQuery: {
                storeName: "",
                schoolId: "",
                productId: "cloud_campus",
                pageNum: 1,
                pageRow: 20,
            },
            // 头部筛选
            formData: {
                formInline: {
                    storeName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "仓库名称",
                        key: "storeName",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["assetWarehouseAssets:list"],
                    },
                ]
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "仓库名称",
                        prop: "storeName",
                        type: "popoverText",
                        align: 'center'
                    },
                    {
                        label: "资产总数",
                        prop: "fixedCount",
                        align: 'center',
                    },
                    {
                        type: "function",
                        label: "资产总额",
                        prop: "fixedTotal",
                        align: 'right',
                        callBack(row) {
                            if (row.fixedTotal) {
                                return row.fixedTotal.toLocaleString();
                            } else {
                                return 0;
                            }
                        },
                    },
                    {
                        label: "耗材总数",
                        prop: "consumeCount",
                        align: 'center',
                    },
                    {
                        type: "function",
                        label: "耗材总额",
                        prop: "consumeTotal",
                        align: 'right',
                        callBack(row) {
                            if (row.consumeTotal) {
                                return row.consumeTotal.toLocaleString();
                            } else {
                                return 0;
                            }
                        },
                    },
                    {
                        label: "操作",
                        type: "slot",
                        align: 'center',
                        slotName: "operation",
                        labelWidth: "220",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                height: "",
                rowkey: "id",
                isExpandAll: true,
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            // 表头
            backgroundHeader: "#FAFBFC",
            // 表头字体
            headerColor: "#595959",
            total: 0,
        };
    },
    computed: {},
    created() {
        this.listQuery.schoolId = this.$store.state.schoolId;
        this.init();
    },
    mounted() {
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.$refs.table) {
            this.$refs.table.$refs.multipleTable.doLayout();
        }
    },
    methods: {
        /**
         * 初始化
         * getList  初始化列表
         * clickBtn 查询/重置
         */
        init() {
            //赋值--存储筛选项及滚动条高度
            this.setPageInfo();
            this.getList();
        },
        getList() {
            this.loadingTable = true;
            const systemModel = new SystemModel();
            systemModel.getStoreList(this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    this.table_data = res.data.data.list;
                    this.total = res.data.data.totalCount;
                    this.totalMoney = res.data.data.total;
                });
                this.loadingTable = false;
                this.setTableScrollHeight();
            });
        },
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary":
                    this.formData.data.forEach((item) => {
                        if (item.key === "storeName") {
                            this.formData.formInline.storeName = item.value;
                            this.listQuery.storeName = item.value;
                        }
                    });
                    this.listQuery.pageNum = 1;
                    this.getList();
                    break;
                case "reset":
                    this.formData.data.forEach((item) => {
                        item.value = "";
                    });
                    this.listQuery.storeName = "";
                    this.listQuery.pageNum = 1;
                    this.getList();
                    break;
            }
        },

        /**
         * 操作按钮
         * goWarehouse  进入库房
         * goFlow   出入库流水
         */
        goWarehouse(data) {
            this.savePageInfo();
            this.$emit("changeComp", {
                index: 1,
                data: data,
            });
        },
        goFlow(data) {
            this.savePageInfo();
            this.$emit("changeComp", {
                index: 2,
                data: data,
            });
        },
        savePageInfo(){
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
        },
        setPageInfo(){
            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
        },
        setTableScrollHeight(){
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                setTimeout(()=>{
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                    //移除--存储筛选项及滚动条高度
                    recordsList.remove(this.$route.name) 
                },500)
            }
        },
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.title {
    width: 100%;
    height: 20px;
    background-color: #ffffff;
    padding: 20px;
    font-size: 20px;
    color: red;
    font-weight: bold;
    margin-bottom: 2px;
}
::v-deep .el-table th.el-table__cell > .cell{
    padding-right: 100px;
}
::v-deep .el-table .cell{
    padding-right: 100px;
}
</style>
